import React from "react";

const UserAction = ({
  user,
  onDelete,
  onImpersonate,
  onSuspend,
  onUnsuspend,
}) => {
  const suspendButtonProps = user.suspended
    ? {
        onClick: () => onUnsuspend(user.id),
        className:
          "w-28 bg-deep-blue text-white font-semibold px-4 py-2 rounded hover:bg-indigo-800 mr-2",
        children: "Unsuspend",
      }
    : {
        onClick: () => onSuspend(user.id),
        className:
          "w-28 bg-punch-green text-black font-bold px-4 py-2 rounded hover:bg-other-turquoise mr-2",
        children: "Suspend",
      };

  return (
    <div className="flex justify-center">
      <a
        href={`/users/${user.id}/edit`}
        className={
          "bg-other-turquoise text-black font-bold px-4 py-2 rounded hover:bg-green-600 mr-2 text-center mr-2"
        }
      >
        Edit
      </a>
      <button
        onClick={() => onImpersonate(user.id)}
        className={
          "bg-stat-blue text-black font-bold px-4 py-2 rounded hover:bg-blue-500 mr-2"
        }
      >
        Impersonate
      </button>
      <button {...suspendButtonProps}>
        <span className="block text-center">{suspendButtonProps.children}</span>
      </button>
      <button
        onClick={() => onDelete(user.id)}
        className={
          "bg-red-500 text-black font-bold px-4 py-2 rounded hover:bg-red-700 mr-2"
        }
      >
        Delete
      </button>
    </div>
  );
};

export default UserAction;
