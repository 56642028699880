import React from "react";
import statvisIcon from "images/statvis-icon.png";

const ContactSupport = () => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gradient-to-br from-stat-blue to-deep-blue">
      <div className="max-w-md p-8 bg-white rounded-lg shadow-md text-center">
        <img
          src={statvisIcon}
          alt="Statvis Logo"
          className="mx-auto mb-6 w-32"
        />
        <h1 className="text-4xl font-bold mb-4 text-[#282930]">
          Account Inactive
        </h1>
        <p className="mb-2 text-[#282930] text-center">
          Your account is currently inactive.
        </p>
        <p className="mb-6 text-[#282930] text-center whitespace-nowrap">
          Please contact our support team for assistance.
        </p>
        <a
          href="mailto:support@statvis.com"
          className="inline-block px-6 py-3 bg-punch-green text-grey-800 text-lg font-semibold rounded-lg hover:bg-other-turquoise transition duration-300"
        >
          Contact Support
        </a>
      </div>
    </div>
  );
};

export default ContactSupport;
